import React from "react";
import {WorkGroup} from "../../../../API/types";
import {Box} from "@mui/material";
import PageHeader from "../../../../components/PageHeader";
import {Permission, ResourceGroupToggleButtonType, resourceGroupToggleButtonTypeValues} from "../../../../types";
import Planner from "../../../../components/Planner";
import routes from "../../../../routes";
import NavigationButton from "../../../../components/Buttons/NavigationButton";
import {useAppDispatch, useWorkGroupsPlanningView} from "../../../../hooks";
import {PlannerType} from "../../../../components/Planner/types";
import {useResourceTypeToggle} from "../../../../store/viewSlice";
import {setDialogData} from "../../../../store/plannerSlice";


export default function WorkGroupsPlanningView() {
    const dispatch = useAppDispatch();
    const [resourceTypeToggleButtonValue, handleResourceTypeChange] = useResourceTypeToggle<ResourceGroupToggleButtonType>();

    useWorkGroupsPlanningView(true);

    const handleOpenWorkGroupDetailsDialog = (workGroup: WorkGroup | null = null, shouldCreateNewVersion?: boolean) => {
        dispatch(setDialogData({
            addOrEditWorkGroupDetails: {
                workGroup: workGroup,
                shouldCreateNewVersion: shouldCreateNewVersion
            }
        }));
    };

    return (
        <Box sx={{position: 'relative', display: 'flex', flexDirection: 'column', height: '100%', maxHeight: '100%'}}>
            <PageHeader
                title="Töögrupid"
                toggleProps={{
                    options: [...resourceGroupToggleButtonTypeValues],
                    value: resourceTypeToggleButtonValue,
                    handleChange: handleResourceTypeChange,
                }}
                buttonProps={{
                    title: 'Lisa töögrupp',
                    onClick: () => handleOpenWorkGroupDetailsDialog(),
                    permission: Permission.UpdateWorkGroups
                }}
                secondaryButton={
                    <NavigationButton text="Nimekiri" route={routes.AUTHENTICATED.WORK_GROUPS.LIST} />
                }
                showRegionSelect
            />
            <Planner type={PlannerType.WORK_GROUP} />
        </Box>
    );
}
