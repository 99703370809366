import React from "react";
import {Box, IconButton, Tooltip} from "@mui/material";
import {Permission} from "../../../types";
import Visible from "../../Visible";
import {
    AddCircle,
    AddComment,
    CallMerge,
    CallSplit,
    ContentPasteGoRounded,
    Delete,
    Download,
    Edit,
    ForwardToInboxRounded,
    PublishedWithChanges,
    Visibility
} from "@mui/icons-material";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {ICON_COLOR, ICON_COLOR_DISABLED, ICON_SIZE_SMALL, IconSize} from "../utils";

export const actionIconTypeValues = [
    'EDIT',
    'VIEW',
    'DOWNLOAD',
    'GENERATE',
    'DELETE',
    'ADD_COMMENT',
    'SPLIT',
    'MERGE',
    'COPY_TO_CLIPBOARD',
    'COPY_EMAIL_INFO',
    'ADD_OPPOSITE_GROUP',
] as const;
export type ActionIconType = typeof actionIconTypeValues[number];

interface ActionIconData {
    tooltipTitle: string;
    icon: ReactJSXElement;
}

const getIcon = (type: ActionIconType, size: IconSize, disabled?: boolean, color?: string): ActionIconData => {
    let styles = disabled ? ICON_COLOR_DISABLED : color ? {color: color} : ICON_COLOR;
    if (size === 'small') styles = {...styles, ...ICON_SIZE_SMALL};

    switch (type) {
        case 'EDIT':
            return {tooltipTitle: 'Muuda', icon: <Edit sx={styles} />};
        case 'VIEW':
            return {tooltipTitle: 'Vaata', icon: <Visibility sx={styles} />};
        case 'DOWNLOAD':
            return {tooltipTitle: 'Lae alla', icon: <Download sx={styles} />};
        case 'GENERATE':
            return {tooltipTitle: 'Genereeri', icon: <PublishedWithChanges sx={styles} />};
        case 'DELETE':
            return {tooltipTitle: 'Kustuta', icon: <Delete sx={styles} />};
        case 'ADD_COMMENT':
            return {tooltipTitle: 'Lisa kommentaar', icon: <AddComment sx={styles} />};
        case 'SPLIT':
            return {tooltipTitle: 'Jaota reis kaheks', icon: <CallSplit sx={{...styles, rotate: '180deg'}} />};
        case 'MERGE':
            return {tooltipTitle: 'Liida reisi osad', icon: <CallMerge sx={{...styles, rotate: '180deg'}} />};
        case 'COPY_TO_CLIPBOARD':
            return {tooltipTitle: 'Kopeeri', icon: <ContentPasteGoRounded sx={styles} />};
        case 'COPY_EMAIL_INFO':
            return {tooltipTitle: 'Kopeeri', icon: <ForwardToInboxRounded sx={styles} />};
        case 'ADD_OPPOSITE_GROUP':
            return {tooltipTitle: 'Lisa vastasgrupp', icon: <AddCircle sx={styles} />};
    }
};

interface ActionIconProps {
    type: ActionIconType;
    id: number;
    handleClick: (id: number) => void;
    tooltipTitle?: string;
    disabled?: boolean;
    color?: string;
    size?: IconSize;
    navPermission?: Permission;
}

const ActionIcon = ({type, id, handleClick, tooltipTitle, disabled, color, size = 'small', navPermission}: ActionIconProps) => {
    const iconData = getIcon(type, size, disabled, color);

    return (
        <Visible permission={navPermission}>
            <Tooltip title={tooltipTitle ?? iconData.tooltipTitle} arrow placement="top">
                <Box component="span">
                    <IconButton size="small" onClick={() => handleClick(id)} disabled={disabled}>
                        {iconData.icon}
                    </IconButton>
                </Box>
            </Tooltip>
        </Visible>
    );
};

export default ActionIcon;
