import React from 'react';
import {Box, SpeedDial, SpeedDialAction, useTheme} from "@mui/material";
import {AltRoute, Edit, LeakAdd, Menu as MenuIcon, MultipleStop} from "@mui/icons-material";
import {EntityId} from "@reduxjs/toolkit";
import {addPreparationAndFinishingTime, setDialogData} from "../../../../../../../../../store/plannerSlice";
import {useAppDispatch, useAppSelector} from "../../../../../../../../../hooks";
import {selectDisplayWorkGroupByIdMemoized} from "../../../../../../../store/selectors";
import {WorkGroupActivity, WorkGroupTripDefinition} from "../../../../../../../../../API/workGroup/types";


interface WorkGroupCardMenuProps {
    groupId: EntityId;
}

const WorkGroupCardMenu = ({groupId}: WorkGroupCardMenuProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const workGroup = useAppSelector(state => selectDisplayWorkGroupByIdMemoized(state, groupId));
    if (!workGroup) return <></>;

    const workGroupHasOppositeWorkGroups =
        [...workGroup.activities, ...workGroup.tripDefinitions].find(
            (item: WorkGroupTripDefinition | WorkGroupActivity) =>
                item.workGroupsOfOppositeType?.length && item.workGroupsOfOppositeType.length > 0
        );

    return (
        <Box sx={{position: 'relative'}}>
            <SpeedDial
                ariaLabel="work-group-menu"
                icon={<MenuIcon sx={{fontSize: '25px'}} />}
                direction="right"
                transitionDuration={{enter: 0}}
                sx={{
                    position: 'absolute',
                    zIndex: 30,
                    top: -18,
                    right: workGroupHasOppositeWorkGroups ? -154 : -199,
                    bottom: 'auto',
                    left: 'auto',
                    '& .MuiFab-primary': {
                        border: '1px solid',
                        borderColor: theme.palette.divider,
                        color: 'text.secondary',
                        backgroundColor: 'common.white',
                        width: 35,
                        height: 35,
                        boxShadow: '0px 0px 5px lightgrey',
                        ':hover': {
                            backgroundColor: 'action.hover'
                        }
                    },
                    transform: 'scale(0.6)',
                }}>
                <SpeedDialAction
                    icon={<Edit sx={{fontSize: '30px'}} />}
                    tooltipTitle="Muuda töögrupi andmeid"
                    arrow
                    onClick={() => dispatch(setDialogData({
                        addOrEditWorkGroupDetails: {workGroup: workGroup}
                    }))}
                />
                <SpeedDialAction
                    icon={<MultipleStop sx={{fontSize: '30px'}} />}
                    tooltipTitle="Lisa ettevalmistus- ja lõpetusaeg"
                    arrow
                    onClick={() => dispatch(addPreparationAndFinishingTime(groupId))}
                />
                <SpeedDialAction
                    icon={<AltRoute sx={{fontSize: '30px'}} />}
                    tooltipTitle="Kopeeri ja lõpeta vana versioon"
                    arrow
                    onClick={() => dispatch(setDialogData({
                        addOrEditWorkGroupDetails: {workGroup: workGroup, shouldCreateNewVersion: true}
                    }))}
                />
                {!workGroupHasOppositeWorkGroups &&
                    <SpeedDialAction
                        icon={<LeakAdd sx={{fontSize: '30px'}} />}
                        tooltipTitle="Loo vastastöögrupp samade tegevustega"
                        arrow
                        onClick={() => dispatch(setDialogData({
                            copyOppositeWorkGroup: {workGroup: workGroup}
                        }))}
                    />
                }
            </SpeedDial>
        </Box>
    );
};

export default WorkGroupCardMenu;
