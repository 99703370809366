import dayjs, {Dayjs} from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {StartAndEndTimeWithModifier} from "../components/Planner/types";
import {getNumberWithSign} from "./utils";

dayjs.extend(customParseFormat);

export const getDisplayDate = (date: string | Date | Dayjs) => dayjs(date).format('DD.MM.YYYY');

export const getDisplayNullableDate = (date?: string | Date | Dayjs | null, useEllipsisFallback: boolean = false) =>
    date ? getDisplayDate(date) : (useEllipsisFallback ? '...' : '');

export const getDisplayDateTime = (date: Dayjs | Date | string): string => dayjs(date).format('DD.MM.YY HH:mm');

export const getDisplayDateWithoutYear = (date: Dayjs | Date | string): string => dayjs(date).format('DD.MM');

export const getDisplayDayAndWrittenMonth = (date: string) => dayjs(date).format('D. MMM');

export const getDisplayTimeWithModifier = (time: string | Date | Dayjs, hasModifier: boolean) => {
    const format = typeof time === 'string' ? ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm', 'HH:mm'] : undefined;
    return `${dayjs(time, format).format('HH:mm')}${hasModifier ? '(+1)' : ''}`;
};

export const getDateString = (date: Date | Dayjs): string => dayjs(date).format('YYYY-MM-DD');

export const getDateTimeString = (date: Date | Dayjs | string): string => dayjs(date).format('YYYY-MM-DDTHH:mm:ss');

export const getYearMonthString = (date: Dayjs): string => date.format('YYYY-MM');

export const getDate = (date: string): Date => dayjs(date).toDate();

export const Weekday = new Map<number, string>([
    [1, 'E'],
    [2, 'T'],
    [3, 'K'],
    [4, 'N'],
    [5, 'R'],
    [6, 'L'],
    [0, 'P'],
]);

export const getHhMmFromDate = (date: Date | Dayjs | string): string => {
    const dayjsDate = dayjs(date);
    return dayjsDate.format('HH:mm:00');
};

export const getDateFromTimeStr = (time: string): Dayjs => {
    return dayjs(time, 'HH:mm');
};

export const getDateWithModifierFromHhNr = (hoursDecimal: number): { date: Date; isOnNextDay: boolean } => {
    const currentDate = new Date();
    const hh = Math.floor(hoursDecimal);
    const mm = Math.round((hoursDecimal - hh) * 60);
    const isOnNextDay = hh >= 24;
    currentDate.setHours(isOnNextDay ? hh - 24 : hh, mm, 0, 0);

    return {date: currentDate, isOnNextDay};
};

export const getDateWithAddedDays = (date: Date, addedDays: number) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + addedDays);
    return newDate;
};

export const getDayjsDateTimeFromDateWithModifier = (time: Date | Dayjs | null, isOnNextDay: boolean, date?: string) =>
    dayjs(`${date} ${dayjs(time).format('HH:mm')}`)
        .add(isOnNextDay ? 1 : 0, 'day');

export const getDayjsTimeFromDateTimeStr = (dateTime: string) => {
    const currentDate = dayjs();
    return dayjs(`${currentDate.format('YYYY-MM-DD')}T${dayjs(dateTime).format('HH:mm:ss')}`);
};

export const getStrDateTimeFromDateWithModifier = (time: Date | Dayjs | null, isOnNextDay: boolean, date?: string) =>
    getDayjsDateTimeFromDateWithModifier(dayjs(time), isOnNextDay, date).format('YYYY-MM-DDTHH:mm:ss');

export const getTimeWithModifierFromDateTimeStr = (dateTime: string, currentDate: Dayjs) => {
    const dayjsDate = dayjs(dateTime);

    const time = dayjsDate.format('HH:mm');
    const isOnNextDay = currentDate.isBefore(dayjsDate, 'day');

    return {time, isOnNextDay};
};

export const getStartAndEndTimeWithModifierFromDateTimeStr = (
    startDateTime: string,
    endDateTime: string,
    currentDate: string | Date | Dayjs
): StartAndEndTimeWithModifier => {
    const currentDayjs = dayjs(currentDate);
    const startTimeWithModifier = getTimeWithModifierFromDateTimeStr(startDateTime, currentDayjs);
    const endTimeWithModifier = getTimeWithModifierFromDateTimeStr(endDateTime, currentDayjs);

    return {
        startTime: startTimeWithModifier.time,
        startTimeIsOnNextDay: startTimeWithModifier.isOnNextDay,
        endTime: endTimeWithModifier.time,
        endTimeIsOnNextDay: endTimeWithModifier.isOnNextDay,
    };
};

export const getDisplayTimeRange = ({startTime, startTimeIsOnNextDay, endTime, endTimeIsOnNextDay}: {
    startTime: string | Date | Dayjs,
    startTimeIsOnNextDay: boolean,
    endTime: string | Date | Dayjs,
    endTimeIsOnNextDay: boolean,
}) => `${getDisplayTimeWithModifier(startTime, startTimeIsOnNextDay)}-${getDisplayTimeWithModifier(endTime, endTimeIsOnNextDay)}`;

export const getDisplayDateTimeRange = ({startDateTime, endDateTime}: {
    startDateTime: string | Date | Dayjs,
    endDateTime: string | Date | Dayjs,
}) => `${getDisplayDateTime(startDateTime)} - ${getDisplayDateTime(endDateTime)}`;

export const getDisplayValidDatesRange = ({validFrom, validTo, useEllipsisFallback}: {
    validFrom?: string | Date | Dayjs | null,
    validTo?: string | Date | Dayjs | null,
    useEllipsisFallback?: boolean,
}): string => {
    return `${getDisplayNullableDate(validFrom)} - ${getDisplayNullableDate(validTo, useEllipsisFallback)}`;
};

type NullableDate = string | Date | Dayjs | null | undefined;

export const findClosestItemByValidRange = <T extends { validFrom?: NullableDate, validTo?: NullableDate }, >(
    items: T[],
    date?: NullableDate
): T => {
    const minDate = new Date(-8640000000000000);
    const maxDate = new Date(8640000000000000);
    const targetDate = dayjs(date);

    return items.reduce((result: T, item: T): T =>
        [
            {item: result, diff: Math.abs(dayjs(result.validFrom ?? minDate).diff(targetDate, 'days'))},
            {item: result, diff: Math.abs(dayjs(result.validTo ?? maxDate).diff(targetDate, 'days'))},
            {item: item, diff: Math.abs(dayjs(item.validFrom ?? minDate).diff(targetDate, 'days'))},
            {item: item, diff: Math.abs(dayjs(item.validTo ?? maxDate).diff(targetDate, 'days'))},
        ].reduce(
            (a, b) => a.diff < b.diff ? a : b
        ).item
    );
};
export const formatTimeAtDate = (dateTime: string | Date | Dayjs, date: string | Date | Dayjs) => {
    const time = dayjs(dateTime);
    const diff = time.diff(date, 'days');
    if (diff !== 0) {
        return `${time.format('HH:mm')} (${getNumberWithSign(diff)})`;
    }

    return time.format('HH:mm');
};

export const isInMonth = (month: Dayjs, startDate: string, endDate?: string | null) => {
    const start = dayjs(startDate);
    const end = endDate ? dayjs(endDate) : undefined;

    return !start.isAfter(month.endOf('month')) && (!end || !end?.isBefore(month.startOf('month')));
};

export const isOnDay = (day: Dayjs, startDate: string, endDate?: string | null): boolean => {
    const start = dayjs(startDate);
    const end = endDate ? dayjs(endDate) : undefined;

    return !start.isAfter(day.endOf('day')) && (!end || !end.isBefore(day.startOf('day')));
};

export const startOfPreviousMonth = () => dayjs().subtract(1, 'month').startOf('month');
