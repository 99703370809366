import Dialog from "@mui/material/Dialog";
import {Box, FormHelperText, Typography} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "../../../../Buttons/Button";
import {Check, Clear} from "@mui/icons-material";
import React, {useEffect, useMemo, useState} from "react";
import {SplitTripForm} from "./types";
import NumberTextField from "../../../../Form/NumberTextField";
import {validationSchema} from "../../../../../utils/formValidation";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {
    selectDialogData,
    clearDialogData,
    selectOrderedSegmentsByTripId,
    splitTrip,
} from "../../../../../store/plannerSlice";
import {getRouteTxt} from "../../../../../scenes/authenticated/reports/formatters/workGroupReportFormatter";
import {setToast} from "../../../../../store/toastSlice";
import {RoutePoint, WorkGroupTripDefinition} from "../../../../../API/types";
import RoutePointSelect from "./components/RoutePointSelect";
import {decimalToDisplayStr, strToDecimal} from "../../../../../utils/utils";
import * as Yup from "yup";
import {round} from "lodash";


export const tripSplitValidationSchema = Yup.object().shape({
    distance1: validationSchema('Esimese lõigu pikkus').fields.textFieldRequired,
    splitRoutePoint: validationSchema('Jaotuspunkt').fields.objectRequired,
    distance2: validationSchema('Teise lõigu pikkus').fields.textFieldRequired,
});

const getCombinedRouteAndSplitPoint = (tripSegments: WorkGroupTripDefinition[]): {route: RoutePoint[], splitRoutePoint: RoutePoint | null} => {
    if (!tripSegments || !tripSegments.length) return ({route: [], splitRoutePoint: null});
    if (tripSegments.length === 1) return ({route: tripSegments[0].route ?? [], splitRoutePoint: null});

    const route1 = tripSegments[0].route ?? [];
    const splitRoutePoint = route1.length ? route1[route1.length - 1] : null;
    const route2WithoutDuplicate = tripSegments[1].route ? tripSegments[1].route.slice(1) : [];

    return {
        route: [...route1, ...route2WithoutDuplicate],
        splitRoutePoint: splitRoutePoint
    };
};

export default function SplitTrip() {
    const dispatch = useAppDispatch();
    const splitTripDialogData = useAppSelector(selectDialogData).splitTrip;
    const tripSegments = useAppSelector(state => selectOrderedSegmentsByTripId(state, splitTripDialogData?.tripId ?? 0));

    if (!splitTripDialogData) return null;
    if (!tripSegments.length) {
        dispatch(setToast({type: 'error', text: 'Reisi ei leitud'}));
        return null;
    }
    const [defaultFormData, setDefaultFormData] = useState<SplitTripForm | undefined>(undefined);
    const {route, splitRoutePoint} = useMemo(() => getCombinedRouteAndSplitPoint(tripSegments), [tripSegments]);
    const tripCode = tripSegments[0].code;
    const expectedTotalDistance = tripSegments.reduce((acc, obj) => acc + (obj.distance ?? 0), 0);

    useEffect(() => {
        if (route) {
            setDefaultFormData({
                splitRoutePoint: splitRoutePoint,
                distance1: decimalToDisplayStr(tripSegments[0].distance) ?? '',
                distance2: tripSegments.length > 1 && tripSegments[1].distance ? decimalToDisplayStr(tripSegments[1].distance) : '',
            });
        }
    }, [route]);

    const getDistanceSumAndExpectedTotalDiff = (distance1: string | null, distance2: string | null) => {
        const distance1AsNr = distance1 ? strToDecimal(distance1) : 0;
        const distance2AsNr = distance2 ? strToDecimal(distance2) : 0;
        return round(expectedTotalDistance - distance1AsNr - distance2AsNr, 3);
    };

    const renderTotalDistanceDiffersError = (distance1: string | null, distance2: string | null) => {
        const totalDiff = getDistanceSumAndExpectedTotalDiff(distance1, distance2);

        return totalDiff ? (
            <FormHelperText sx={{color: 'error.main', mt: -0.5, mx: 1.75}}>
                Jaotatud lõikude pikkuste summa on {Math.abs(totalDiff)} km {totalDiff > 0 ? 'väiksem' : 'suurem'} kui reisi oodatud pikkus ({expectedTotalDistance} km).
            </FormHelperText>
        ) : null;
    };

    const handleFormSubmitClick = (form: SplitTripForm, formHelpers: FormikHelpers<SplitTripForm>) => {
        dispatch(splitTrip({tripId: splitTripDialogData.tripId, originalSegments: tripSegments, form, formHelpers}));
    };

    return (
        <Dialog open={!!splitTripDialogData} onClose={() => dispatch(clearDialogData())}>
            <Box sx={{width: '450px', maxWidth: '100%', p: {xs: 0, sm: 2}}}>
                <DialogTitle>
                    <Typography variant="h5" component="div">
                        {tripSegments.length > 1 ? `Muuda ${tripCode} jaotuvust` : `Jaota ${tripCode} kaheks`}
                    </Typography>
                    {tripSegments.length > 0 && tripSegments[0].route &&
                        <Typography variant="body2" color="text.secondary">{getRouteTxt(tripSegments[0].route)}</Typography>
                    }
                </DialogTitle>
                {defaultFormData &&
                    <Formik initialValues={defaultFormData} validationSchema={tripSplitValidationSchema} onSubmit={handleFormSubmitClick}>
                        {(formikProps: FormikProps<SplitTripForm>) => {
                            useEffect(() => {
                                const diff = getDistanceSumAndExpectedTotalDiff(formikProps.values.distance1, formikProps.values.distance2);
                                const currentDistance1 = strToDecimal(formikProps.values.distance1) ?? 0;

                                if (currentDistance1 < expectedTotalDistance && diff) {
                                    formikProps.setFieldValue('distance2', decimalToDisplayStr(round(expectedTotalDistance - currentDistance1, 3)).toString());
                                }
                            }, [formikProps.values.distance1]);

                            useEffect(() => {
                                const diff = getDistanceSumAndExpectedTotalDiff(formikProps.values.distance1, formikProps.values.distance2);
                                const currentDistance2 = strToDecimal(formikProps.values.distance2) ?? 0;

                                if (currentDistance2 < expectedTotalDistance && diff) {
                                    formikProps.setFieldValue('distance1', decimalToDisplayStr(round(expectedTotalDistance - currentDistance2, 3)).toString());
                                }
                            }, [formikProps.values.distance2]);

                            return (
                                <Form>
                                    <DialogContent sx={{display: 'flex', flexDirection: 'column', pt: 0}}>
                                        <NumberTextField name="distance1" label="Esimese lõigu pikkus (km)" decimals={2} />
                                        <RoutePointSelect name="splitRoutePoint" label="Jaotuspunkt" options={route} />
                                        <NumberTextField name="distance2" label="Teise lõigu pikkus (km)" decimals={2} />
                                        {renderTotalDistanceDiffersError(formikProps.values.distance1, formikProps.values.distance2)}
                                    </DialogContent>
                                    <DialogActions sx={{justifyContent: 'center', maxWidth: '100%', mb: 2}}>
                                        <Box maxWidth="50%">
                                            <Button
                                                text="Loobu"
                                                variant="outlined"
                                                onClick={() => dispatch(clearDialogData())}
                                                startIcon={<Clear />}
                                            />
                                        </Box>
                                        <Box maxWidth="50%">
                                            <Button
                                                text="Salvesta"
                                                type="submit"
                                                disabled={formikProps.isSubmitting}
                                                startIcon={<Check />}
                                            />
                                        </Box>
                                    </DialogActions>
                                </Form>
                            );
                        }}
                    </Formik>
                }
            </Box>
        </Dialog>
    );
}
