import React, {useCallback, useContext} from "react";
import {Box, useMediaQuery} from "@mui/material";
import {EntityId} from "@reduxjs/toolkit";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {
    selectContractStats,
    selectCurrentMonthDays,
    selectExtraDriverIds,
    selectIsRowActive,
    selectMonth,
    selectSortedActiveDriverIds
} from "../../store/selectors";
import {selectDriverContractById} from "../../../../../store/driverContractSlice";
import {ToastContext} from "../../../../../contexts/ToastContext";
import {updateDriversOrder} from "../../store/actions";
import CustomCell from "./CustomCell";
import {ArrowForwardIos} from "@mui/icons-material";
import {DriverContractTypeContractions} from "../../types";
import {useLocalStorage} from "usehooks-ts";
import {getContractTranslation} from "../../../../../utils/enumTranslations";
import {contractIsActiveOnMonth, formatName, isOverOrUnderTargetHours} from "../../utils";
import {red} from "@mui/material/colors";
import {
    collapsedColumnSx,
    DEFAULT_CELL_WIDTH,
    disabledBackground,
    LARGE_CELL_WIDTH,
    NAME_CELL_WIDTH,
    SMALL_CELL_WIDTH
} from "../index";
import ItemCell from "./ItemCell";
import PrevWeekItems from "./PrevWeekItems";
import {selectDriverById} from "../../../../../store/driverSlice";
import {shallowEqual} from "react-redux";
import {selectSelectedRegion} from "../../../../../store/regionSlice";
import {Virtuoso} from "react-virtuoso";

const Sorting = ({driverId, isFirst, isLast}: {
    driverId: EntityId,
    isFirst: boolean,
    isLast: boolean,
}) => {
    const {addToast} = useContext(ToastContext);
    const dispatch = useAppDispatch();
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const driver = useAppSelector(state => selectDriverById(state, driverId));

    const handleChangeOrder = useCallback(async (up: boolean) => {
        if (selectedRegion) {
            const resultAction = await dispatch(updateDriversOrder({id: driverId, up, selectedRegion}));
            if (updateDriversOrder.fulfilled.match(resultAction)) {
                addToast({ type: 'success', text: 'Bussijuhtide järjekord uuendatud' });
            } else {
                addToast({ type: 'error', text: 'Bussijuhtide järjekorra muutmine ebaõnnestus' });
            }
        }
    }, [driverId, selectedRegion]);
    const moveUp = useCallback(() => handleChangeOrder(true), [handleChangeOrder]);
    const moveDown = useCallback(() => handleChangeOrder(false), [handleChangeOrder]);

    const includeSorting = driver?.regionIds.includes(selectedRegion?.id ?? 0) && driver?.active;

    return (
        <CustomCell sx={{borderRight: 'none', minWidth: SMALL_CELL_WIDTH, maxWidth: SMALL_CELL_WIDTH, backgroundColor: driver?.active ? undefined : disabledBackground}} content={
            includeSorting ?
                <Box sx={{displayPrint: 'none', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                    <ArrowForwardIos
                        sx={{
                            opacity: isFirst ? 0.3 : undefined,
                            display: 'block',
                            cursor: 'pointer',
                            fontSize: 'medium',
                            rotate: '-90deg'
                        }}
                        onClick={isFirst ? undefined : moveUp}
                        role="button"
                        aria-disabled={isFirst} />
                    <ArrowForwardIos
                        sx={{
                            opacity: isLast ? 0.3 : undefined,
                            display: 'block',
                            cursor: 'pointer',
                            fontSize: 'medium',
                            rotate: '90deg'
                        }}
                        onClick={isLast ? undefined : moveDown}
                        role="button"
                        aria-disabled={isLast} />
                </Box>
                : null
        } />
    );
};

const Stats = ({id}: {id: EntityId}) => {
    const stats = useAppSelector(state => selectContractStats(state, id), shallowEqual);

    return (
        <>
            <CustomCell
                key="nominalHours"
                width={LARGE_CELL_WIDTH}
                sx={{textAlign: 'right'}}
                content={<Box sx={{px:0.25}}>{stats.nominalHours.toFixed(2)}</Box>} />
            <CustomCell
                key="plannedHours"
                width={LARGE_CELL_WIDTH}
                sx={{textAlign: 'right'}}
                content={<Box sx={{px:0.25}}>{stats.plannedHours.toFixed(2)}</Box>} />
            <CustomCell
                key="overUnder"
                width={LARGE_CELL_WIDTH}
                sx={{textAlign: 'right'}}
                content={
                    <Box color={
                        isOverOrUnderTargetHours(
                            stats.plannedHours,
                            stats.nominalHours
                        ) ? red[700] : undefined
                    }
                    sx={{px: 0.25}}>
                        {stats.difference.toFixed(2)}
                    </Box>
                } />
        </>
    );
};

const ExtraStats = ({id}: {id: EntityId}) => {
    const [showExtra] = useLocalStorage('workScheduleShowExtra', false);
    const stats = useAppSelector(state => selectContractStats(state, id), shallowEqual);

    if (!showExtra) {
        return (
            <CustomCell
                content=""
                sx={{...collapsedColumnSx, borderRightWidth: '1px'}}
            />
        );
    }

    return (
        <>
            <CustomCell
                key="contractType"
                content={DriverContractTypeContractions.get(stats.contractType)}
                tooltip={getContractTranslation(stats.contractType)}
                width={DEFAULT_CELL_WIDTH}
                sx={{borderLeftWidth: '2px', displayPrint: 'none'}} />
            <CustomCell
                key="weeklyHours"
                content={stats.weeklyHours}
                width={DEFAULT_CELL_WIDTH}
                sx={{displayPrint: 'none'}} />
            <CustomCell
                key="workedDays"
                content={stats.workedDays}
                width={DEFAULT_CELL_WIDTH}
                sx={{displayPrint: 'none'}} />
            <CustomCell
                key="averageDailyHours"
                content={stats.averageDailyHours.toFixed(1)}
                width={DEFAULT_CELL_WIDTH}
                sx={{displayPrint: 'none'}} />
            <CustomCell
                key="workedWeekendDays"
                content={stats.workedWeekendDays}
                width={DEFAULT_CELL_WIDTH}
                sx={{displayPrint: 'none'}} />
            <CustomCell
                key="reserveDays"
                content={stats.reserveDays}
                width={DEFAULT_CELL_WIDTH}
                sx={{displayPrint: 'none'}} />
        </>
    );
};

const ContractRow = ({id}: {
    id: EntityId,
}) => {
    const contract = useAppSelector(state => selectDriverContractById(state, id));
    const driver = useAppSelector(state => selectDriverById(state, contract?.driverId ?? ''));
    const currentMonthDays = useAppSelector(selectCurrentMonthDays);
    const isRowActive = useAppSelector(state => selectIsRowActive(state, id));

    if (!contract || !driver) {
        return null;
    }

    return (
        <Box sx={{display: 'flex', backgroundColor: isRowActive ? undefined : disabledBackground, '@media print': {pageBreakInside: 'avoid'}}} role="row">
            <CustomCell
                content={formatName(driver, 'lastNameFirst')}
                width={NAME_CELL_WIDTH}
                sx={{textAlign: 'left', height: '38px', px: 0.5}}
            />
            <Stats id={id} />
            <ExtraStats id={id} />
            <PrevWeekItems resourceId={id} />
            {currentMonthDays.map(day => <ItemCell key={day.toString()} rowId={parseInt(id.toString())} day={day} />)}
        </Box>
    );
};

export interface DriverRowProps {
    id: EntityId;
    isFirst: boolean;
    isLast: boolean;
}

export const DriverRow = ({id, isFirst, isLast}: DriverRowProps) => {
    const driver = useAppSelector((state) => selectDriverById(state, id), shallowEqual);
    const month = useAppSelector(selectMonth);

    if (!driver) {
        return null;
    }

    const driverContracts = driver.contracts
        .filter(contract => contractIsActiveOnMonth(contract, month));

    return (
        <Box sx={{display: 'flex'}}>
            <Sorting driverId={driver.id} isFirst={isFirst} isLast={isLast} />
            <Box sx={{flexGrow: 1}}>
            {driverContracts.map((contract) =>
                <ContractRow
                    key={contract.id}
                    id={contract.id?.toString() ?? ''}
                />
            )}
            </Box>
        </Box>
    );
};

export default function DriverRows({tableRef}: {tableRef: HTMLDivElement}) {
    const sortedIds = useAppSelector(selectSortedActiveDriverIds);
    const extraIds = useAppSelector(selectExtraDriverIds);
    const isPrintView = useMediaQuery('print');

    const extraIdsNotInSorted = extraIds.filter(id => !sortedIds.includes(id));

    const data: DriverRowProps[] = [
        ...sortedIds.map((id, index, array) => ({id: id, isFirst: 0 === index, isLast: index + 1 === array.length})),
        ...extraIdsNotInSorted.map((id) => ({id: id, isFirst: false, isLast: false})),
    ];

    if (isPrintView) {
        return <>{data.map((row) => <DriverRow key={row.id} {...row} />)}</>;
    }

    return (
        <Virtuoso
            data={data}
            customScrollParent={tableRef}
            itemContent={(_, props) => <DriverRow {...props} />}
        />
    );
}