import routes from '../../../routes';
import {
    AspectRatio,
    Assessment,
    Assignment,
    Badge,
    ConstructionRounded,
    DateRange,
    DirectionsBus,
    Diversity2,
    ModeOfTravel,
    PendingActions,
    PeopleAlt,
    RouteRounded,
    SavingsRounded,
    WorkHistory
} from '@mui/icons-material';
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {SvgIconTypeMap} from "@mui/material";
import {Permission} from "../../../types";


export interface MenuItem {
    name: string;
    link: string;
    icon: OverridableComponent<SvgIconTypeMap>;
    permission?: Permission;
}

const menuItems: MenuItem[] = [
    {
        name: 'Avaleht',
        link: routes.AUTHENTICATED.DASHBOARD,
        icon: AspectRatio,
        permission: undefined,
    },
    {
        name: 'Bussid',
        link: routes.AUTHENTICATED.BUSES.ROOT,
        icon: DirectionsBus,
        permission: Permission.ReadBuses,
    },
    {
        name: 'Bussijuhid',
        link: routes.AUTHENTICATED.DRIVERS.ROOT,
        icon: Badge,
        permission: Permission.ReadDrivers,
    },
    {
        name: 'Reisid',
        link: routes.AUTHENTICATED.TRIPS.ROOT,
        icon: ModeOfTravel,
        permission: Permission.ReadTripDefinitions,
    },
    {
        name: 'Tellimusveod',
        link: routes.AUTHENTICATED.CHARTER_TRIPS.ROOT,
        icon: RouteRounded,
        permission: Permission.ReadCharterTrips,
    },
    {
        name: 'Töögrupid',
        link: routes.AUTHENTICATED.WORK_GROUPS.ROOT,
        icon: Diversity2,
        permission: Permission.ReadWorkGroups,
    },
    {
        name: 'Tööajakava',
        link: routes.AUTHENTICATED.WORK_SCHEDULE.ROOT,
        icon: WorkHistory,
        permission: Permission.ReadWorkSchedules,
    },
    {
        name: 'Päevaplaan',
        link: routes.AUTHENTICATED.WORK_SHEETS.ROOT,
        icon: PendingActions,
        permission: Permission.ReadCalendarEntries,
    },
    {
        name: 'Veolepingud',
        link: routes.AUTHENTICATED.TRANSPORT_CONTRACTS.ROOT,
        icon: Assignment,
        permission: Permission.ReadTransportContracts,
    },
    {
        name: 'Kasutajad',
        link: routes.AUTHENTICATED.USERS,
        icon: PeopleAlt,
        permission: Permission.ReadUsers,
    },
    {
        name: 'Kalender',
        link: routes.AUTHENTICATED.CALENDAR,
        icon: DateRange,
        permission: Permission.ReadCalendarEntries,
    },
    {
        name: 'Palgalehed',
        link: routes.AUTHENTICATED.PAYROLL.ROOT,
        icon: SavingsRounded,
        permission: Permission.ReadPayroll,
    },
    {
        name: 'Aruanded',
        link: routes.AUTHENTICATED.REPORTS,
        icon: Assessment,
        permission: Permission.ReadWorkSchedules,
    },
    {
        name: 'Rikked',
        link: routes.AUTHENTICATED.DEFECTS.ROOT,
        icon: ConstructionRounded,
        permission: Permission.ListDefects,
    },
];

export default menuItems;
