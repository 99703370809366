import React, {useMemo} from 'react';
import Dialog from '@mui/material/Dialog';
import {Box, DialogActions, DialogContent, Typography} from "@mui/material";
import {ResourceType, WorkGroupItemType} from '../../../../../API/types';
import Button from "../../../../Buttons/Button";
import {Add, Clear} from "@mui/icons-material";
import DialogTitle from "@mui/material/DialogTitle";
import {AddOppositeWorkSheetForm, WorkScheduleItemWithId} from "./types";
import {Form, Formik, FormikHelpers} from 'formik';
import * as Yup from "yup";
import {validationSchema} from "../../../../../utils/formValidation";
import {getWorkGroupItemTypeTranslation, getResourceTypeTranslation} from "../../../../../utils/enumTranslations";
import Autocomplete from "../../../../Form/Autocomplete";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {
    addOppositeWorkSheetToActivity,
    clearDialogData,
    selectDialogData,
} from "../../../../../store/plannerSlice";
import {selectToggledResourceType} from "../../../../../store/viewSlice";
import {selectPlannerWorkItemById, selectWorkScheduleItemById, selectWorkSheetsOfOppositeTypeWithResourceNameMemoized} from "../../../../../store/workScheduleItemSlice";
import {getOppositeResourceType, getStartAndEndPointLabel} from "../../../../../utils/utils";


const oppositeWorkSheetValidationSchema = (label: string) => Yup.object().shape({
    workSheet: validationSchema(label).fields.objectRequired,
});

const defaultValues: AddOppositeWorkSheetForm = {
    workSheet: null
};

export default function AddOppositeWorkSheetDialog() {
    const dispatch = useAppDispatch();

    const addOppositeWorkSheetToWorkItemDialogData = useAppSelector(selectDialogData).addOppositeWorkSheetToWorkItem;
    if (!addOppositeWorkSheetToWorkItemDialogData) return <></>;

    const resourceType = useAppSelector(state => selectToggledResourceType(state));
    const workSheet = useAppSelector(state => selectWorkScheduleItemById(state, addOppositeWorkSheetToWorkItemDialogData.workSheetId));
    const workItem = useAppSelector(state => selectPlannerWorkItemById(state, addOppositeWorkSheetToWorkItemDialogData.workItemId));
    const workSheetsOfOppositeType: WorkScheduleItemWithId[] = useAppSelector(state => selectWorkSheetsOfOppositeTypeWithResourceNameMemoized(state));
    if (!workSheet || !workItem) return <></>;

    const oppositeResourceType = getOppositeResourceType(workSheet.resourceType);

    const handleFormSubmitClick = (form: AddOppositeWorkSheetForm, formHelpers: FormikHelpers<AddOppositeWorkSheetForm>) => {
        if (form.workSheet) {
            formHelpers.setSubmitting(true);
            dispatch(addOppositeWorkSheetToActivity({
                workItemId: addOppositeWorkSheetToWorkItemDialogData.workItemId,
                driverWorkSheetId: resourceType === ResourceType.DRIVER ? Number(workSheet.id) : form.workSheet.id,
                busWorkSheetId: resourceType === ResourceType.VEHICLE ? Number(workSheet.id) : form.workSheet.id,
            })).finally(() => formHelpers.setSubmitting(false));
        }
    };

    const title = useMemo(() => {
        if (workItem.type === WorkGroupItemType.TRIP_DEFINITION) {
            if (workItem.charterTripId) {
                return `Tellimusveole ${workItem.charterTripId} ${workItem.route}`
            } else {
                return `Reisile ${workItem.code} ${workItem.route && getStartAndEndPointLabel(workItem.route)}`;
            }
        }

        return `Tegevusele ${getWorkGroupItemTypeTranslation(workItem.type).toLowerCase()}`
    }, [workItem]);

    return (
        <Dialog open={!!addOppositeWorkSheetToWorkItemDialogData} onClose={() => dispatch(clearDialogData())}>
            <Box sx={{width: '400px', maxWidth: '100%', mt: 1, p: {xs: 0, sm: 3}}}>
                <DialogTitle>
                    <Typography variant="h5" component="div">
                        {getResourceTypeTranslation(oppositeResourceType, 'genitive')} lisamine
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {title}
                    </Typography>
                </DialogTitle>
                <Formik
                    initialValues={defaultValues}
                    validationSchema={oppositeWorkSheetValidationSchema(getResourceTypeTranslation(oppositeResourceType))}
                    onSubmit={handleFormSubmitClick}
                >
                    <Form>
                        <DialogContent sx={{pt: 0}}>
                            <Autocomplete
                                options={workSheetsOfOppositeType}
                                name="workSheet"
                                label={getResourceTypeTranslation(oppositeResourceType)}
                                getOptionLabel={option => option.resourceName}
                                noOptionsText={'Genereeritud sõidulehega '
                                    + getResourceTypeTranslation(oppositeResourceType, 'genitive').toLowerCase()
                                    + ' ei leitud'}
                            />
                        </DialogContent>
                        <DialogActions sx={{justifyContent: 'center', maxWidth: '100%'}}>
                            <Box maxWidth="50%">
                                <Button
                                    text="Loobu"
                                    variant="outlined"
                                    onClick={() => dispatch(clearDialogData())}
                                    startIcon={<Clear />}
                                />
                            </Box>
                            <Box maxWidth="50%">
                                <Button text="Lisa" type="submit" startIcon={<Add />} />
                            </Box>
                        </DialogActions>
                    </Form>
                </Formik>
            </Box>
        </Dialog>
    );
}
