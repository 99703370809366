import {ResourceType, WorkGroup, WorkGroupActivity, WorkGroupItemType, WorkGroupTripDefinition,} from "../../API/types";
import {
    CharterTripWorkItem as WorkSheetCharterTripWorkItem,
    TripDefinitionWorkItem as WorkSheetTripDefinitionWorkItem,
    WorkItem as WorkSheetWorkItem
} from "../../store/workScheduleItemSlice";
import {EntityId} from "@reduxjs/toolkit";


export enum PlannerType {
    WORK_GROUP = 'WORK_GROUP',
    WORK_SHEET = 'WORK_SHEET'
}

export interface PlannerTimeline {
    startTime: number;
    duration: number;
    displayedHours: string[];
    minWidth: number;
}

export interface PlannerItemGroup {
    id: EntityId;
    type: ResourceType;
    workGroupCode: string;
    tripDefinitionIds: number[];
    activityIds: number[];
    workItemIds: number[];
    otherRegionId: number | null;
}

export interface DisplayWorkGroup extends WorkGroup {
    firstItemStartHh: number | null;
    lastItemEndHh: number | null;
}

export interface DisplayWorkGroupItemDetails {
    width: number;
    xPos: number;
}

export interface DisplayWorkGroupTripDefinition extends WorkGroupTripDefinition, DisplayWorkGroupItemDetails {}

export interface DisplayWorkGroupActivity extends Omit<WorkGroupActivity, 'id'>, DisplayWorkGroupItemDetails {
    id: number;
    comment: string | null;
}

export interface StartAndEndTimeWithModifier {
    startTime: string;
    startTimeIsOnNextDay: boolean;
    endTime: string;
    endTimeIsOnNextDay: boolean;
}

export interface StartAndEndTimeWithModifierAndType extends StartAndEndTimeWithModifier{
    type: WorkGroupItemType;
}

export interface StartAndEndTimeWithModifierAndId extends StartAndEndTimeWithModifier {
    id: number;
}

export interface PlannerItemStartAndEndHourWithType {
    type: WorkGroupItemType;
    startHh: number;
    endHh: number;
}

export interface PlannerItemStartAndEndHourWithTypeAndId extends PlannerItemStartAndEndHourWithType{
    id: number;
}

export interface WorkItem extends WorkSheetWorkItem, StartAndEndTimeWithModifier {}
export interface TripDefinitionWorkItem extends WorkSheetTripDefinitionWorkItem, StartAndEndTimeWithModifier {}
export interface CharterTripWorkItem extends WorkSheetCharterTripWorkItem, StartAndEndTimeWithModifier {}
export interface DisplayWorkItem extends WorkItem, DisplayWorkGroupItemDetails {}
export interface DisplayTripDefinitionWorkItem extends TripDefinitionWorkItem, DisplayWorkGroupItemDetails {}
export interface DisplayCharterTripWorkItem extends CharterTripWorkItem, DisplayWorkGroupItemDetails {}

export type CardItemType = WorkGroupTripDefinition | DisplayWorkGroupActivity | DisplayWorkItem | DisplayTripDefinitionWorkItem | DisplayCharterTripWorkItem;

export type UnassignedTripsSectionHeight = '20%' | '40%';

export interface ContentScroll {
    value: number;
    origin: ScrollOrigin;
}

export type ScrollOrigin = 'assigned' | 'unassigned';
